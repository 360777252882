.footer {
    position: relative;
    margin-top: auto; 
    width: 100%;
    background: transparent;
    color: #494949;
    text-align: center;
    padding: 10px 0;
  }
  
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .main-content {
    flex: 1; 
    display: flex;
    justify-content: center;
    align-items: center;
  }


@media (max-width: 600px) {
  .footer {
    font-size: 0.7rem;
  }
}
  